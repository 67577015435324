<div class="forgot-password-wrapper">

    @switch(currentStage) {
        @case (ForgotPasswordStage.UserVerification) {
            <div class="text header text-center">{{ langx['FORGOT_PASSWORD_HEADER'] }}</div>
            <div class="text text-center text-small">{{ langx['FORGOT_PASSWORD_USER_VERIFICATION'] }}</div>
        }

        @case (ForgotPasswordStage.OtpChannelSelection) {
            <div class="text header text-center">{{ langx['FORGOT_PASSWORD_HEADER'] }}</div>
            <div class="text text-center text-small">{{ langx['FORGOT_PASSWORD_OTP_CHANNEL_SELECTION'] }}</div>
        }

        @case (ForgotPasswordStage.OtpVerification) {
            <div class="text header text-center">{{ langx['FORGOT_PASSWORD_HEADER'] }}</div>
            @if(selectedOtpChannel === OtpChannel.MOBILE) {
                <div class="text text-center text-small">{{ langx['FORGOT_PASSWORD_OTP_VERIFICATION_SMS'] }}</div>
            } @else {
                <div class="text text-center text-small">{{ langx['FORGOT_PASSWORD_OTP_VERIFICATION_EMAIL'] }}</div>
            }
        }

        @case (ForgotPasswordStage.ChangePassword) {
            <div class="text header text-center">{{ langx['FORGOT_PASSWORD_HEADER_PASSWORD_CHANGE'] }}</div>
            <div class="text text-center text-small">{{ langx['FORGOT_PASSWORD_CHANGE_PASSWORD'] }}</div>
        }
    }

    <div class="progress-bar">
        <div class="bar" [class.done]="currentStage >= 0"></div>
        <div class="bar" [class.done]="currentStage >= 1"></div>
        <div class="bar" [class.done]="currentStage >= 2"></div>
        <div class="bar" [class.done]="currentStage >= 3"></div>
    </div>

    @switch (currentStage) {
        @case (ForgotPasswordStage.UserVerification) {
            <div class="forgot-password-stage user-verification">
                <div class="form-wrapper">

                    <form [formGroup]="userVerificationForm" class="forgot-password-form" (submit)="onValidateUserVerificationClick()">
                        <div class="form-group username">
                            <input type="text" class="form-control {{ !isCredentialsInvalid()?'':'invalid' }}" placeholder="{{ langx['FORGOT_PASSWORD_USERNAME'] }}" formControlName="loginName"
                                   (blur)="onLoginNameBlur()" (focus)="onFieldFocus('loginName')" (keyup)="onRealNameOrLoginNameFieldKeyUp($event)" #loginNameInputField/>
                            <app-clear-input-icon [targetInputField]="loginNameInputField"></app-clear-input-icon>
                            
                            @if(loginNameControl.dirty && loginNameControl.hasError('whitespace') || (loginNameControl.hasError('required') && isValidLoginName) ) {
                                <p class="forgot-password-field-error alert-text text-small">
                                    <span class="material-symbols-outlined">error</span>
                                    {{ langx['FORGOT_PASSWORD_USERNAME_EMPTY'] }}
                                </p>
                            }
                        </div>

                        <div class="form-group real-name">
                            <input type="text" class="form-control {{ !isCredentialsInvalid()?'':'invalid' }}" placeholder="{{ langx['FORGOT_PASSWORD_REALNAME'] }}" formControlName="realName"
                                   (blur)="onRealNameBlur()" (focus)="onFieldFocus('realName')" (keyup)="onRealNameOrLoginNameFieldKeyUp($event)" #realNameInputField/>
                            <app-clear-input-icon [targetInputField]="realNameInputField"></app-clear-input-icon>
                            
                            
                            @if(realNameControl.dirty && realNameControl.hasError('whitespace') || (realNameControl.hasError('required') && isValidRealName)) {
                                <p class="forgot-password-field-error alert-text text-small">
                                    <span class="material-symbols-outlined">error</span>
                                    {{ langx['FORGOT_PASSWORD_REALNAME_EMPTY'] }}
                                </p>
                            } @else if( isCredentialsInvalid() ) {
                                <p class="forgot-password-field-error alert-text text-small">
                                    <span class="material-symbols-outlined">error</span>
                                    {{ getApiErrorForField('realName')?.message }}
                                </p>
                            }
                        </div>

                        <div class="form-group">
                            <div class="verification-code">
                                <input type="text" class="form-control {{ ((verificationCodeControl.dirty && verificationCodeControl.invalid) || isVerificationCodeInvalid())?'invalid':''}}" maxlength="4" placeholder="{{ langx['FORGOT_PASSWORD_VERIFICATION_CODE'] }}" formControlName="verificationCode"
                                  (blur)="onVerificationCodeBlur()" (focus)="onFieldFocus('verificationCode')" (keyup)="onVerificationCodeKeyUp()" #verificationCodeInputField />
                                <div class="input-group-append">
                                    <span class="img-verification-code-wrapper">
                                        @if(verificationCodeImageBase64) {
                                            <img [src]="verificationCodeImageBase64" alt="" class="img-verification-code">
                                        }
                                    </span>
                                    <span class="material-symbols-outlined icon-refresh md-24"
                                        (click)="onRefreshVerificationCodeIconClick()">refresh
                                    </span>
                                </div>
                            </div>

                            @if(verificationCodeControl.dirty && verificationCodeControl.hasError('whitespace') || (verificationCodeControl.hasError('required') && isValidVerificationCode)) {
                                <p class="forgot-password-field-error alert-text text-small">
                                    <span class="material-symbols-outlined">error</span>
                                    {{ langx['FORGOT_PASSWORD_VERIFICATION_CODE_EMPTY'] }}
                                </p>
                            }

                            @else if((verificationCodeControl.dirty && verificationCodeControl.invalid && verificationCodeControl.value?.length > 3) || isVerificationCodeInvalid()) {
                                <p class="forgot-password-field-error alert-text text-small">
                                    <span class="material-symbols-outlined">error</span>
                                    {{ langx['FORGOT_PASSWORD_INVALID_VERIFICATION_CODE'] }}
                                </p>
                            }

                            @if(!isVerificationCodeDidNotMatch()) {
                                <p class="forgot-password-field-success alert-text text-small">
                                    <span class="material-symbols-outlined">check_circle</span>
                                    {{ langx['FORGOT_PASSWORD_VALID_VERIFICATION_CODE'] }}
                                </p>
                            }

                        </div>

                        <div class="form-group">
                            <button class="btn btn-primary forgot-password-button-margin" [disabled]="userVerificationForm.invalid">{{ langx['FORGOT_PASSWORD_USER_VERIFICATION_CONTINUE'] }}</button>
                        </div>
                    </form>

                </div>
            </div>
        }

        @case (ForgotPasswordStage.OtpChannelSelection) {
            <div class="forgot-password-stage otp-channel-selection">
                <div class="otp-channels-wrapper">
                    <div class="otp-channel mobile" [class.selected]="selectedOtpChannel === OtpChannel.MOBILE" [class.disabled]="!userVerificationResponse?.mobileVerified" (click)="onForgotPasswordSendOtpClick(OtpChannel.MOBILE)">
                        <div class="left">
                            <img class="icon-mobile" src="{{ (mediaServer$ | async) }}/zeta/user/forgot-password/icon_phone{{ userVerificationResponse?.mobileVerified? '': '-disabled' }}.png" alt="">
                        </div>
                        <div class="right">
                            <div class="text text-bold text-white text-heavy">{{ langx['FORGOT_PASSWORD_VIA_SMS'] }}</div>
                            <div class="text text-bold text-white">{{ userVerificationResponse?.mobile }}</div>
                        </div>
                    </div>

                    <div class="otp-channel email" [class.selected]="selectedOtpChannel === OtpChannel.EMAIL" [class.disabled]="!userVerificationResponse?.emailVerified" (click)="onForgotPasswordSendOtpClick(OtpChannel.EMAIL)">
                        <div class="left">
                            <img class="icon-email" src="{{ (mediaServer$ | async) }}/zeta/user/forgot-password/icon_envelope{{ userVerificationResponse?.emailVerified? '': '-disabled' }}.png" alt="">
                        </div>
                        <div class="right">
                            <div class="text text-bold text-white text-heavy">{{ langx['FORGOT_PASSWORD_VIA_EMAIL'] }}</div>
                            <div class="text text-bold text-white">{{ userVerificationResponse?.email }}</div>
                        </div>
                    </div>
                </div>
            </div>
        }

        @case (ForgotPasswordStage.OtpVerification) {
            <div class="otp-verification forgot-password-stage">
                <div class="otp-verification-wrapper">
                    <form [formGroup]="otpVerificationForm" (submit)="onForgotPasswordVerifyOtpClick()">
                        <div class="form-group otp">
                            <input (paste)="onOtpInputPaste($event, 0)" (input)="onOtpFieldInputInIndex($event, 0)" (keyup)="onOtpFieldKeyUpInIndex($event, 0)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[0]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl0">
                            <input (paste)="onOtpInputPaste($event, 1)" (input)="onOtpFieldInputInIndex($event, 1)" (keyup)="onOtpFieldKeyUpInIndex($event, 1)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[1]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl1">
                            <input (paste)="onOtpInputPaste($event, 2)" (input)="onOtpFieldInputInIndex($event, 2)" (keyup)="onOtpFieldKeyUpInIndex($event, 2)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[2]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl2">
                            <input (paste)="onOtpInputPaste($event, 3)" (input)="onOtpFieldInputInIndex($event, 3)" (keyup)="onOtpFieldKeyUpInIndex($event, 3)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[3]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl3">
                            <input (paste)="onOtpInputPaste($event, 4)" (input)="onOtpFieldInputInIndex($event, 4)" (keyup)="onOtpFieldKeyUpInIndex($event, 4)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[4]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl4">
                        </div>

                        @if (shouldShowOtpErrorMessage) {
                            <p class="forgot-password-field-error alert-text text-smaller">
                                <span class="material-symbols-outlined">error</span>
                                {{ otpErrorMessage }}
                            </p>
                        }

                        <div class="message-hint-wrapper">
                            <div class="message-hint text-center text-small">{{ langx['FORGOT_PASSWORD_OTP_EXPIRE'] }}</div>
                        </div>

                        <div class="form-group resend">
                            <div class="text-center text-smaller">
                                <button type="button" class="btn btn-just-text" (click)="onResendOtpClick()" [disabled]="timeRemaining$ | async">
                                    {{ langx['FORGOT_PASSWORD_OTP_RESEND'] }}
                                    @if(timeRemaining$ | async) {
                                        <span class="timer">in {{(timeRemaining$ | async) | date: 'm:ss'}}</span>
                                    }
                                </button>
                            </div>
                        </div>

                        <div class="form-group submit">
                            <button class="btn btn-primary" [disabled]="otpVerificationForm.invalid">{{ langx['FORGOT_PASSWORD_OTP_VERIFY'] }}</button>
                        </div>
                    </form>
            </div>
        </div>
        }

        @case (ForgotPasswordStage.ChangePassword) {
            <div class="change-password forgot-password-stage">
                <div class="form-wrapper">
                    <form [formGroup]="changePasswordForm" class="form-change-password" (submit)="onForgotPasswordSubmitClick()">
                        
                            <div class="form-group password">
                                <input type="{{ isPasswordShow?'text':'password' }}" class="form-control" placeholder="{{ langx['FORGOT_PASSWORD_PLACEHOLDER_PASSWORD' ]}}" formControlName="password" (focus)="onChangePasswordFocus()" (blur)="onChangePasswordBlur()">
                                <span class="show-password-icon-wrapper">
                                    <span class="material-symbols-outlined" (click)="onToggleShowPassword()">
                                        @if(isPasswordShow) {
                                            <img class="logo icon-password-visibility {{ passwordControl.value?'':'opaque' }}" src="{{ (mediaServer$ | async) }}/zeta/common/icon_password-visible.png" alt="">
                                        } @else {
                                            <img class="logo icon-password-visibility {{ passwordControl.value?'':'opaque' }}" src="{{ (mediaServer$ | async) }}/zeta/common/icon_password-hidden.png" alt="">
                                        }
                                    </span>
                                </span>
                            </div>
                            @if(!isChangePasswordValidationShow && (passwordControl.touched || passwordControl.dirty)) {
                                @if (isPasswordEmpty()) {
                                    <p class="forgot-password-field-error alert-text text-small">
                                        <span class="material-symbols-outlined">error</span>
                                        {{ langx['FORGOT_PASSWORD_EMPTY_PASSWORD'] }}
                                    </p>
                                }
                                @else if (passwordControl.invalid) {
                                    <p class="forgot-password-field-error alert-text text-small">
                                        <span class="material-symbols-outlined">error</span>
                                        {{ langx['FORGOT_PASSWORD_FORMAT_INVALID'] }}
                                    </p>
                                }
                            }
                            <app-password-strength [show]="isChangePasswordValidationShow" [password]="passwordControl.value"></app-password-strength>
                            <div class="form-group confirm-password">
                                <input type="{{ isConfirmPasswordShow?'text':'password' }}" class="form-control" placeholder="{{ langx['FORGOT_PASSWORD_PLACEHOLDER_CONFIRM_PASSWORD' ]}}" formControlName="confirmPassword">
                                <span class="show-password-icon-wrapper">
                                    <span class="material-symbols-outlined" (click)="onToggleShowConfirmPassword()">
                                        @if(isConfirmPasswordShow) {
                                            <img class="logo icon-password-visibility {{ confirmPasswordControl.value?'':'opaque' }}" src="{{ (mediaServer$ | async) }}/zeta/common/icon_password-visible.png" alt="">
                                        } @else {
                                            <img class="logo icon-password-visibility {{ confirmPasswordControl.value?'':'opaque' }}" src="{{ (mediaServer$ | async) }}/zeta/common/icon_password-hidden.png" alt="">
                                        }
                                    </span>
                                </span>
                            </div>

                            @if(confirmPasswordControl.touched || confirmPasswordControl.dirty) {
                                @if (isConfirmPasswordEmpty()) {
                                    <p class="forgot-password-field-error alert-text text-small">
                                        <span class="material-symbols-outlined">error</span>
                                        {{ langx['FORGOT_PASSWORD_EMPTY_CONFIRM_PASSWORD'] }}
                                    </p>
                                } @else if(changePasswordForm.valid && (confirmPasswordControl.value === passwordControl.value)) {
                                    <p class="forgot-password-field-success alert-text text-small">
                                        <span class="material-symbols-outlined">check_circle</span>
                                        {{ langx['FORGOT_PASSWORD_MATCHED_PASSWORD'] }}
                                    </p>
                                } @else if(confirmPasswordControl.value !== passwordControl.value) {
                                    <p class="forgot-password-field-error alert-text text-small">
                                        <span class="material-symbols-outlined">error</span>
                                        {{ langx['FORGOT_PASSWORD_DIT_NOT_MATCH_PASSWORD'] }}
                                    </p>
                                }
                            }
                            <div class="form-group submit">
                                <button class="btn btn-primary" [disabled]="changePasswordForm.invalid || confirmPasswordControl.value !== passwordControl.value"> {{ langx['FORGOT_PASSWORD_CHANGE_PASSWORD_SUBMIT'] }}</button>
                            </div>
                    </form>
                </div>
            </div>
        }
    }
    <app-cs-chat-icon></app-cs-chat-icon>
</div>