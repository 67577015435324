// eslint-disable-next-line max-len, import-newlines/enforce
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, computed, signal } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, map, takeUntil, takeWhile, timer } from 'rxjs';
import {
  ForgotPasswordChangePasswordDto,
  ForgotPasswordSendOtpDto,
  ForgotPasswordUserVerificationResponse,
  ForgotPasswordVerifyDto,
  ForgotPasswordVerifyOtpDto
} from '../../../shared/models/forgot-password.model';
import { ApiError } from '../../../shared/models/http.model';
import { LocalizedMessages } from '../../../shared/models/i18n.model';
import { CommonService } from '../../../shared/service/common/common.service';
import { ModalService } from '../../../shared/service/modal/modal.service';
import { UserService } from '../../../shared/service/user/user.service';
import { langxObj } from './forgot-password_langx';

/**
 * Represents the screens of ForgotPassword
 */
export enum ForgotPasswordStage {
  UserVerification,
  OtpChannelSelection,
  OtpVerification,
  ChangePassword
}

export enum OtpChannel {
  MOBILE,
  EMAIL
}

export enum ErrorCodes {
  UserNotExists = 'error.forgot-password.user.not-exists',
  VerificationDidNotMatch = 'error.forgot-password.verification-code.not-match',
  OtpMaxRetriesReached = 'error.otp-validation.max-retries-reached'
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})

export class ForgotPasswordComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
  ) {

    this.mediaServer$ = this.commonService.mediaServer$;
    this.commonService.mediaServer$.subscribe(mediaServer => this.mediaServer = mediaServer);

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (lang) => {
          this.langx = langxObj[lang];
        }
      });

    this.initaliazeotpInputFieldFocusSignals();

    this.initializeUserVerificationForm();
    this.initializeOtpVerificationForm();
    this.initializeChangePasswordForm();
  }

  // make the enum accessible in the html template
  readonly ForgotPasswordStage = ForgotPasswordStage;
  readonly OtpChannel = OtpChannel;

  mediaServer$: Observable<string>;
  destroy$: Subject<void> = new Subject();

  verificationCodeImageBase64: string = '';
  currentStage: ForgotPasswordStage = ForgotPasswordStage.UserVerification;

  // list of observables used for autofocus of otp input field
  otpInputFieldFocusSignals$!: any[];
  userVerificationForm!: FormGroup;
  otpVerificationForm!: FormGroup;
  changePasswordForm!: FormGroup;
  langx: LocalizedMessages = {};

  apiErrors$ = signal([] as ApiError[]);

  isVerificationCodeDidNotMatch = signal(true);

  isCredentialsInvalid = computed(() => 
    this.apiErrors$().find( apiError => apiError.code == ErrorCodes.UserNotExists ));

  isVerificationCodeInvalid = computed(() => this.isVerificationCodeDidNotMatch() && 
    this.apiErrors$().find( apiError => apiError.code == ErrorCodes.VerificationDidNotMatch ));

  isLoginNameValid = computed(() => 
    this.apiErrors$().find( apiError => apiError.field === 'loginName'));

  isRealNameValid = computed(() => this.isCredentialsInvalid() && 
    this.apiErrors$().find( apiError => apiError.field === 'realName'));

  isOtpMaxRetriesReached = computed(() => 
    this.apiErrors$().find( apiError => apiError.code == ErrorCodes.OtpMaxRetriesReached ));

  userVerificationResponse: ForgotPasswordUserVerificationResponse | undefined;
  selectedOtpChannel: any;
  isPasswordShow: boolean = false;
  isConfirmPasswordShow: boolean = false;
  loginNameControl!: AbstractControl;
  realNameControl!: AbstractControl;
  verificationCodeControl!: AbstractControl;
  passwordControl!: AbstractControl;
  confirmPasswordControl!: AbstractControl;
  isChangePasswordValidationShow: boolean = false;
  timeRemaining$ = new Observable<number>();
  otpErrorMessage: string = '';
  shouldShowOtpErrorMessage: boolean = false;
  mediaServer: string = '';
  isValidLoginName: boolean = false;
  isValidRealName: boolean = false;
  isValidVerificationCode: boolean = false;

  @ViewChild('loginNameInputField') loginNameInput!: ElementRef;
  @ViewChild('realNameInputField') realNameInput!: ElementRef;
  @ViewChild('verificationCodeInputField') verificationCodeInput!: ElementRef;

  onOtpFieldInputInIndex($event: Event, index: number) {
    const targetInputValue = ($event.target as HTMLInputElement).value;
    if (targetInputValue.match(/^[A-Za-z0-9]{1}$/g) && index < (this.otpInputFieldFocusSignals$.length - 1)) {
      this.otpInputFieldFocusSignals$[index + 1].next(true);
      this.shouldShowOtpErrorMessage = false;
    }
  }
  
  /**
   * Explicitly used for handling backspace because
   * onInput doesn't register an input event if there's no deleted value 
   * when Backspace is pressed
   * 
   * @param $event 
   * @param index 
   */
  onOtpFieldKeyUpInIndex($event: KeyboardEvent, index: number) {
    const targetInputValue = ($event.target as HTMLInputElement).value;
    if($event.key === 'Backspace' && index > 0 && !targetInputValue) {
      this.otpInputFieldFocusSignals$[index - 1].next(true);
    }
  }

  ngOnInit(): void {
    this.fetchVerificationCode();
    this.commonService.showBackButtonUntil(this.destroy$);
    this.commonService.preloadImages([
      this.mediaServer + '/zeta/common/icon_password-visible.png',
      this.mediaServer + '/zeta/common/icon_password-hidden.png',
      this.mediaServer + 'zeta/user/forgot-password/icon_phone.png',
      this.mediaServer + 'zeta/user/forgot-password/icon_phone-disabled.png',
      this.mediaServer + '/zeta/user/forgot-password/icon_envelope.png',
      this.mediaServer + '/zeta/user/forgot-password/icon_envelope-disabled.png'
    ]);

  }

  ngAfterViewInit(): void {
    this.addFocusEventListeners();
  }

  addFocusEventListeners() {
    this.addFocusListener(this.loginNameInput, 'loginName');
    this.addFocusListener(this.realNameInput, 'realName');
    this.addFocusListener(this.verificationCodeInput, 'verificationCode');
  }

  addFocusListener(element: ElementRef, field: string) {
    this.renderer.listen(element.nativeElement, 'focus', () => {
      const control = this.userVerificationForm.get(field);
      if (control) {
        this.reactivateValidator(control, field);
      }
    });
  }

  onFieldFocus(field: string) {
    const control = this.userVerificationForm.get(field);
    if (control) {
      this.reactivateValidator(control, field);
    }
  }

  fetchVerificationCode() {
    this.verificationCodeImageBase64 = '';
    this.userService.fetchForgotPasswordVerificationCodeImage().subscribe(ret => {
      if (ret.status === 'success') {
        this.verificationCodeImageBase64 = ret.data.verificationCodeImageBase64;
      }
    });
  }

  onRefreshVerificationCodeIconClick() {
    this.verificationCodeControl.setValue('');
    this.isVerificationCodeDidNotMatch.set(true);
    this.fetchVerificationCode();
  }

  onPageHeaderBackIconClick() {
    this.currentStage -= 1;
    this.clearAllValidators();
    this.switchStage(this.currentStage);
  }

  clearAllValidators() {
    Object.keys(this.userVerificationForm.controls).forEach(field => {
      const control = this.userVerificationForm.get(field);
      control?.clearValidators();
      control?.updateValueAndValidity();
    });
  }

  reactivateValidator(control: any, field: string) {
    switch (field) {
      case 'loginName':
        control.setValidators([Validators.required, this.noWhiteSpaceValidator]);
        break;
      case 'realName':
        control.setValidators([Validators.required, this.noWhiteSpaceValidator]);
        break;
      case 'verificationCode': {
      
        control.setValidators([Validators.required, 
          Validators.pattern('^[0-9]{4}$'), this.noWhiteSpaceValidator]);
        break;
      }
    }
    control.updateValueAndValidity();
    
  }

  switchStage(stage: ForgotPasswordStage) {
    this.currentStage = stage;
    this.clearApiErrors();
    switch (this.currentStage) {
      case ForgotPasswordStage.UserVerification: {
        this.commonService.showBackButtonUntil(this.destroy$);
        this.isVerificationCodeDidNotMatch.set(true);
        this.userVerificationForm.reset();
        break;
      }

      case ForgotPasswordStage.OtpChannelSelection: {
        this.timeRemaining$ = new Observable<number>();
        this.otpErrorMessage = '';
        this.shouldShowOtpErrorMessage = false;
        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.onPageHeaderBackIconClick();
          });

        this.selectedOtpChannel = null;
        break;
      }

      case ForgotPasswordStage.OtpVerification: {
        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.onPageHeaderBackIconClick();
          });

        this.initaliazeotpInputFieldFocusSignals();
        this.otpVerificationForm.reset();
        break;
      }

      case ForgotPasswordStage.ChangePassword: {
        this.commonService.showBackButtonUntil(this.destroy$, true)
          .subscribe(() => {
            this.onPageHeaderBackIconClick();
          });

        this.isVerificationCodeDidNotMatch.set(true);
        this.changePasswordForm.reset();
        break;
      }
    }
  }

  initializeUserVerificationForm() {
    this.userVerificationForm = this.formBuilder.group({
      loginName: ['', [Validators.required, this.noWhiteSpaceValidator]],
      realName: ['', [Validators.required, this.noWhiteSpaceValidator]],
      verificationCode: ['', [Validators.required, Validators.pattern('^[0-9]{4}$'), this.noWhiteSpaceValidator]]
    });

    this.loginNameControl = this.userVerificationForm.get('loginName')!;
    this.realNameControl = this.userVerificationForm.get('realName')!;
    this.verificationCodeControl = this.userVerificationForm.get('verificationCode')!;

    this.realNameControl.statusChanges.subscribe(status => {
      if(status == 'INVALID') {
        this.clearApiErrors('realName');
      }
    });
  }

  initializeOtpVerificationForm() {
    this.otpVerificationForm = this.formBuilder.group({});
    this.otpInputFieldFocusSignals$.forEach((item, index) => {
      const control = new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9]{1}$')])
      this.otpVerificationForm.addControl(`otpControl${index}`, control);
    });
  }

  initializeChangePasswordForm() {
    const passwordFormatRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@!~%*&^])[A-Za-z\d#@!~%*&^]{6,30}$/;
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.pattern(passwordFormatRegex)
      ]],
      confirmPassword: ['', Validators.required]
    });

    this.passwordControl = this.changePasswordForm.get('password')!;
    this.confirmPasswordControl = this.changePasswordForm.get('confirmPassword')!;
  }

  initaliazeotpInputFieldFocusSignals() {
    this.otpInputFieldFocusSignals$ = [
      new BehaviorSubject<boolean>(true),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false)
    ];
  }

  onValidateUserVerificationClick() {
    const forgotPasswordDto: ForgotPasswordVerifyDto = this.userVerificationForm.value;
    this.validateUserVerification(forgotPasswordDto);
  }

  validateUserVerification(forgotPasswordDto: ForgotPasswordVerifyDto) {
    this.commonService.showLoading();
    this.userService.validateForgotPasswordUserVerification(forgotPasswordDto).subscribe(response => {
      if (response.status === 'success') {
        this.userVerificationResponse = response.data;
        this.isVerificationCodeDidNotMatch.set(false);

        if (this.userVerificationResponse?.emailVerified || this.userVerificationResponse?.mobileVerified) {
          this.switchStage(ForgotPasswordStage.OtpChannelSelection);
        } else {
          this.modalService.showInformation(this.langx['FORGOT_PASSWORD_NO_OTP_CHANNEL']);
        }
        this.onRefreshVerificationCodeIconClick();
      } else {
        this.apiErrors$.set(response.errors ?? []);

        const hasCaptchaVerificationErrorCodes = response.errors?.some(error => error.field == 'verificationCode');
        if (!hasCaptchaVerificationErrorCodes) {
          this.onRefreshVerificationCodeIconClick();
        }
      }
      this.commonService.hideLoading();
    });
  }

  onForgotPasswordSendOtpClick(otpChannel: OtpChannel) {
    if (this.userVerificationResponse?.mobileVerified && OtpChannel.MOBILE === otpChannel
      || this.userVerificationResponse?.emailVerified && OtpChannel.EMAIL === otpChannel) {
      this.selectedOtpChannel = otpChannel;
      const forgotPasswordSendOtpDto = {} as ForgotPasswordSendOtpDto;
      forgotPasswordSendOtpDto.forgotPasswordToken = this.userVerificationResponse?.forgotPasswordToken;

      if (OtpChannel.MOBILE === otpChannel) {
        forgotPasswordSendOtpDto.thruSms = true;
        forgotPasswordSendOtpDto.thruEmail = false;

      } else {
        forgotPasswordSendOtpDto.thruEmail = true;
        forgotPasswordSendOtpDto.thruSms = false;

      }
      this.sendForgotPasswordOtp(forgotPasswordSendOtpDto);
    }
  }

  sendForgotPasswordOtp(forgotPasswordOtpDto: ForgotPasswordSendOtpDto) {
    this.commonService.showLoading();
    this.userService.sendForgotPasswordOtp(forgotPasswordOtpDto).subscribe(response => {
      if (response?.status === 'error') {
        this.modalService.showError( response?.errors?.[0].message ?? '' ).subscribe(() => {
          this.switchStage(ForgotPasswordStage.UserVerification);
        });

      } else {
        const message = forgotPasswordOtpDto.thruSms ?
          this.langx['FORGOT_PASSWORD_OTP_SENT_SMS'] : this.langx['FORGOT_PASSWORD_OTP_SENT_EMAIL'];
        this.modalService.showInformation(message, 
          this.langx['FORGOT_PASSWORD_USER_VERIFICATION_CONTINUE']).subscribe(() => {
          this.switchStage(ForgotPasswordStage.OtpVerification);
        });

      }
      this.commonService.hideLoading();
    });
  }

  onForgotPasswordVerifyOtpClick() {
    const forgotPasswordVerifyOtpDto = {} as ForgotPasswordVerifyOtpDto;
    forgotPasswordVerifyOtpDto.forgotPasswordToken = this.userVerificationResponse?.forgotPasswordToken ?? '';
    forgotPasswordVerifyOtpDto.otp = Object.keys(this.otpVerificationForm.controls)
      .map(control => this.otpVerificationForm.get(control)?.value).join('');

    this.validateForgotPasswordOtp(forgotPasswordVerifyOtpDto);
  }

  validateForgotPasswordOtp(forgotPasswordVerifyOtpDto: ForgotPasswordVerifyOtpDto) {
    this.commonService.showLoading();
    this.userService.validateForgotPasswordOtp(forgotPasswordVerifyOtpDto).subscribe(response => {
      if (response?.status === 'error') {
        this.apiErrors$.set(response.errors ?? []);
        this.otpVerificationForm.reset();

        if (this.isOtpMaxRetriesReached()) {
          this.modalService.showError(this.apiErrors$()?.[0].message ?? '')
            .subscribe(() => {
              this.switchStage(ForgotPasswordStage.UserVerification);
            });
        } else {
          this.otpErrorMessage = this.apiErrors$()?.[0].message;
          this.shouldShowOtpErrorMessage = true;
        }

      } else {
        this.modalService.showSuccess(this.langx['FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL'], 
          this.langx['FORGOT_PASSWORD_USER_VERIFICATION_CONTINUE'])
          .subscribe(() => {
            this.switchStage(ForgotPasswordStage.ChangePassword);
          });

      }
      this.commonService.hideLoading();
    });
  }

  onForgotPasswordSubmitClick() {
    const forgotPasswordChangePasswordDto: ForgotPasswordChangePasswordDto = this.changePasswordForm.value;
    forgotPasswordChangePasswordDto.forgotPasswordToken = this.userVerificationResponse?.forgotPasswordToken ?? '';
    this.changePassword(forgotPasswordChangePasswordDto);
  }

  changePassword(forgotPasswordChangePasswordDto: ForgotPasswordChangePasswordDto) {
    this.commonService.showLoading();
    this.userService.changePassword(forgotPasswordChangePasswordDto).subscribe(response => {
      if (response?.status === 'error') {
        this.modalService.showError(response?.errors?.[0].message ?? '');

      } else {
        this.modalService.showSuccess(this.langx['FORGOT_PASSWORD_SUCCESSFUL_UPDATED_PASSWORD'])
          .subscribe(() => {
            this.router.navigate(['../login'], { relativeTo: this.route });
          });

      }
      this.clearApiErrors();
      this.commonService.hideLoading();
    });
  }

  noWhiteSpaceValidator(control: FormControl) {
    return control.value?.trim().length ? null : { 'whitespace': true };
  }

  onToggleShowPassword() {
    this.isPasswordShow = !this.isPasswordShow;
  }

  onToggleShowConfirmPassword() {
    this.isConfirmPasswordShow = !this.isConfirmPasswordShow;
  }

  onChangePasswordFocus() {
    this.isChangePasswordValidationShow = true;
  }

  onChangePasswordBlur() {
    this.isChangePasswordValidationShow = false;
  }

  onResendOtpClick() {
    const countdownDuration = 5 * 60; // 5 minutes
    this.timeRemaining$ = timer(0, 1000).pipe(map(n => (countdownDuration - n) * 1000), takeWhile(n => n >= 0));
    this.otpVerificationForm.reset();
    this.otpErrorMessage = '';
    this.shouldShowOtpErrorMessage = false;

    this.onForgotPasswordSendOtpClick(this.selectedOtpChannel);
  }

  onOtpInputPaste($event: ClipboardEvent, index: number) {
    if (index <= (this.otpInputFieldFocusSignals$.length - 1)) {
      const formControls = Object.keys(this.otpVerificationForm.controls).slice(index); //get all controls start @ index
      const values = $event.clipboardData?.getData('text').split('') ?? [];

      for (let i = 0; i < formControls.length; i++) {
        if (index < this.otpInputFieldFocusSignals$.length - 1) {
          this.otpInputFieldFocusSignals$[index].next(true);
          index++;
        }
        this.otpVerificationForm.get(formControls[i])?.setValue(values[i]);
      }
    }
  }

  onLoginNameBlur() {
    setTimeout(() => {
      this.isValidLoginName = true;
      const loginNameControl = this.loginNameControl.get('loginName');
      if (loginNameControl?.pristine && loginNameControl?.value.trim()) {
        this.isValidLoginName = false;
      }
    }, 10);
  }

  onRealNameBlur() {
    setTimeout(() => {
      this.isValidRealName = true;
      const realNameControl = this.realNameControl.get('realName');
      if (realNameControl?.pristine && realNameControl?.value.trim()) {
        this.isValidRealName = false;
      }
    }, 10);
  }

  onVerificationCodeBlur() {
    setTimeout(() => {
      this.isValidVerificationCode = true;
      const VerificationCodeControl = this.verificationCodeControl.get('verificationCode');
      if (VerificationCodeControl?.pristine && VerificationCodeControl?.value.trim()) {
        this.isValidVerificationCode = false;
      }
    }, 10);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearApiErrors(field: string = '') {
    if( field == '' ) {
      this.apiErrors$.set([]);
    } else {
      this.apiErrors$.set(
        this.apiErrors$().filter( apiError => apiError.field != field)
      );
    }
  }

  onVerificationCodeKeyUp() {
    if( this.apiErrors$().find( apiError => apiError.code == ErrorCodes.VerificationDidNotMatch)) {
      this.clearApiErrors('verificationCode');
    }
  }

  getApiErrorForField(field: string) {
    return this.apiErrors$().find(apiError => apiError.field == field);
  }

  onRealNameOrLoginNameFieldKeyUp($event: KeyboardEvent) {
    if($event.key.length == 1 || 
      ['Backspace', 'Delete', 'Space'].some( key => key == $event.key)) {
      this.clearApiErrors('realName');
    }
  }

  isPasswordEmpty(): boolean {
    return this.passwordControl.value == null || this.passwordControl.value.length == 0;
  }

  isConfirmPasswordEmpty(): boolean {
    return this.confirmPasswordControl.value == null || this.confirmPasswordControl.value.length == 0;
  }

}

